export class ScrollDown
{
    scrollDown = document.querySelector('[data-s~="ScrollDown"]')

	constructor(store)
	{
		this.store = store
	}

    scroll()
    {
        $("html, body").animate({
            scrollTop: ($(this.scrollDown).offset().top)
        }, 500)
    }

    watch()
    {
        if (this.scrollDown) {
        this.scrollDown.addEventListener("click", () => {
			this.scroll()
		})
        }
    }
}
