import { Plugin } from "./Plugin.js"
import { Route } from "./Route.js"

export class Application
{
    constructor(store)
    {
        this.store = store

        this.store.application.plugin = new Plugin(this.store)
        this.store.application.route = new Route(this.store)
    }

    run()
    {
        this.store.application.plugin.run()
        this.store.application.route.route()
    }
}
