export class Reveal
{
    constructor(store)
    {
        this.store = store
    }

    run()
    {
        ScrollReveal().reveal('[data-s~="Reveal:1__1"]', { origin: "bottom", delay: 0, distance: "100px", duration: 500, reset: true })

        ScrollReveal().reveal('[data-s~="Reveal:1__2"]', { origin: "bottom", delay: 250, distance: "100px", duration: 500, reset: true })

        ScrollReveal().reveal('[data-s~="Reveal:1__3"]', { origin: "bottom", delay: 500, distance: "100px", duration: 500, reset: true })

        ScrollReveal().reveal('[data-s~="Reveal:1__4"]', { origin: "bottom", delay: 750, distance: "100px", duration: 500, reset: true })

        ScrollReveal().reveal('[data-s~="Reveal:1__5"]', { origin: "bottom", delay: 1000, distance: "100px", duration: 500, reset: true })

        ScrollReveal().reveal('[data-s~="Reveal:1__6"]', { origin: "bottom", delay: 1250, distance: "100px", duration: 500, reset: true })

        ScrollReveal().reveal('[data-s~="Reveal:1__7"]', { origin: "bottom", delay: 1500, distance: "100px", duration: 500, reset: true })

        ScrollReveal().reveal('[data-s~="Reveal:1__8"]', { origin: "bottom", delay: 1750, distance: "100px", duration: 500, reset: true })

        ScrollReveal().reveal('[data-s~="Reveal:1__9"]', { origin: "bottom", delay: 2000, distance: "100px", duration: 500, reset: true })

        ScrollReveal().reveal('[data-s~="Reveal:1__10"]', { origin: "bottom", delay: 2250, distance: "100px", duration: 500, reset: true })

        ScrollReveal().reveal('[data-s~="Reveal:1__11"]', { origin: "bottom", delay: 2500, distance: "100px", duration: 500, reset: true })

        ScrollReveal().reveal('[data-s~="Reveal:1__12"]', { origin: "bottom", delay: 2750, distance: "100px", duration: 500, reset: true })
    }
}
