import Swiper from "swiper/bundle"

export class Plugin
{
    constructor(store)
	{
		this.store = store
    }

    run()
    {
        this.store.plugin.swiper = Swiper
    }
}
