export class Reviews
{
	constructor(store)
	{
		this.store = store

		this.carousel = document.querySelector('[data-s~="Reviews"]')

		this.prev = '[data-s~="Reviews:prev"]'
		this.next = '[data-s~="Reviews:next"]'
		this.pagination = '[data-s~="Reviews:pagination"]'

		this.config = {
			direction: "horizontal",
			loop: false,
			autoplay: {
				delay: 6000,
				disableOnInteraction: true
			},
			speed: 1000,
			navigation: {
				prevEl: this.prev,
				nextEl: this.next
			},
			pagination: {
				el: this.pagination,
				type: "progressbar"
			},
			breakpoints: {
				0: {
					slidesPerView: 1
				},
				600: {
					slidesPerView: 4
				},
			}
		}
	}

	run()
	{
		new this.store.plugin.swiper(this.carousel, this.config)
	}
}
