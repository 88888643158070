import { HomeController } from "../Controller/HomeController.js"

export class Route
{
    constructor(store)
    {
        this.store = store
    }

    route()
    {
        this.store.controller.homeController = new HomeController(this.store)
        this.store.controller.homeController.run()
    }
}
