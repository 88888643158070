export class CategoriesDiscounts
{
    constructor(store)
    {
        this.store = store;
        this.minusButton = document.getElementById('prod_zmniejsz');
        this.amountField = document.getElementById('kosz_ilosc');
        this.plusButton = document.getElementById('prod_zwieksz');
        this.discountsConfigContainer = document.getElementById('meta_rabaty_kat');
        this.metaPriceCointainer = document.getElementById('meta_ceny');
        this.nettoPriceContainer = document.getElementById('cena-parametry') ? document.getElementById('cena-parametry') : document.getElementById('cena-ostateczna');        
        this.bruttoPriceContainer = document.getElementById('cena-parametry') ? document.getElementById('cena-parametry') : document.getElementById('cena-ostateczna');
        this.bruttoPriceContainer = document.getElementById('cena-ostateczna-brutto') ? document.getElementById('cena-ostateczna-brutto') : this.bruttoPriceContainer;
        this.bruttoPriceContainer = document.getElementById('cena-parametry-brutto') ? document.getElementById('cena-parametry-brutto') : this.bruttoPriceContainer;
        this.oldBruttoPriceContainer = document.getElementById('cena-netto-parametry-norm') ? document.getElementById('cena-netto-parametry-norm') : document.getElementById('stara-cena');
        this.oldNettoPriceContainer = document.getElementById('cena-netto-parametry-norm') ? document.getElementById('cena-netto-parametry-norm') : document.getElementById('stara-cena');
        this.bruttoSavingsContainer = document.getElementById('cena-parametry-oszczedzasz');
        this.nettoSavingsContainer = document.getElementById('cena-netto-parametry-oszczedzasz') ? document.getElementById('cena-netto-parametry-oszczedzasz') : document.getElementById('cena-parametry-oszczedzasz');
        this.infoTextContainer = document.getElementById('rabat_kategorii_info');
        this.discountsConfig = null;
    }

    getConfig()
    {
        this.discountConfig = JSON.parse(this.discountsConfigContainer.dataset.config);
    }
    
    getDiscountByAmount(amount)
    {
        amount = parseInt(amount);
        let foundDiscount = null;
        for (let i in this.discountConfig) {
            if (this.discountConfig[i]) {
                let from = parseInt(this.discountConfig[i].od);
                let to = parseInt(this.discountConfig[i].do);
                let discount = parseInt(this.discountConfig[i].rabat);
               
                if (from <= amount && amount <= to) {
                    foundDiscount = discount;
                }
            }
        }
        
        return foundDiscount;
    }
    
    getCurrentAmount()
    {
        return parseInt(this.amountField.value);
    }

    getNettoFlag()
    {
        let nettoFlag = parseInt(this.metaPriceCointainer.dataset.flaganetto);
        return (nettoFlag === 1) ? true : false;
    } 

    getNettoPrice()
    {
        return parseFloat(this.metaPriceCointainer.dataset.cenanetto);
    }    
    
    getBruttoPrice()
    {
        return parseFloat(this.metaPriceCointainer.dataset.cenabrutto);
    }
    
    getOldNettoPrice()
    {
        return parseFloat(this.metaPriceCointainer.dataset.cenanettostara);
    }     
    
    getOldBruttoPrice()
    {
        return parseFloat(this.metaPriceCointainer.dataset.cenabruttostara);
    } 
    
    calculateDiscountedPrice(price, discount)
    {
        return (price - (price * (discount / 100)));
    }
    
    numberFormatForDisplay(number)
    {
        let tmpNumber = Number(number).toLocaleString('hu-HU', {minimumFractionDigits: 2, maximumFractionDigits: 2});
        tmpNumber = tmpNumber.replace(',', '.');
        return tmpNumber;
    }     
    
    numberFormatForCount(number)
    {
        let tmpNumber = Number(number).toLocaleString('hu-HU', {minimumFractionDigits: 2, maximumFractionDigits: 2});
        tmpNumber = tmpNumber.replace(',', '.');
        tmpNumber = tmpNumber.replace(' ', '');
        return tmpNumber;
    }     
    
    manageInfoText(discount)
    {
        if (discount) {
            this.infoTextContainer.style.visibility = 'visible';
            let text = this.infoTextContainer.dataset.text;
            text = text.replace('{{0}}', discount);
            this.infoTextContainer.innerText = text;
        }
        else {
            this.infoTextContainer.style.visibility = 'hidden';
        }
    }

    updateNetto()
    {
        let currentAmount = this.getCurrentAmount();
        let discount = this.getDiscountByAmount(currentAmount);

        this.manageInfoText(discount);
        
        let nettoPrice = this.getNettoPrice();
        let bruttoPrice = this.getBruttoPrice();                
        let newNettoPrice = this.numberFormatForCount(this.calculateDiscountedPrice(nettoPrice, discount));
        let newBruttoPrice = this.numberFormatForCount(this.calculateDiscountedPrice(bruttoPrice, discount));
        this.nettoPriceContainer.innerText = this.numberFormatForDisplay(newNettoPrice);
        this.bruttoPriceContainer.innerText = this.numberFormatForDisplay(newBruttoPrice);
        
        let oldNettoPrice;
        let newOldNettoPrice;
        
        if (this.oldNettoPriceContainer) {
            let oldNettoPrice = this.getOldNettoPrice();
            newOldNettoPrice = this.numberFormatForCount(oldNettoPrice);
        }
        
        if (this.nettoSavingsContainer) {
            this.nettoSavingsContainer.innerText = this.numberFormatForDisplay(newOldNettoPrice - newNettoPrice);
        }
    }    
    
    updateBrutto()
    {
        let currentAmount = this.getCurrentAmount();
        let discount = this.getDiscountByAmount(currentAmount);

        this.manageInfoText(discount);
        
        let bruttoPrice = this.getBruttoPrice();        
        let newBruttoPrice = this.numberFormatForCount(this.calculateDiscountedPrice(bruttoPrice, discount));
        this.bruttoPriceContainer.innerText = this.numberFormatForDisplay(newBruttoPrice);
        
        let oldBruttoPrice;
        let newOldBruttoPrice;
        
        if (this.oldBruttoPriceContainer) {
            let oldBruttoPrice = this.getOldBruttoPrice();
            newOldBruttoPrice = this.numberFormatForCount(oldBruttoPrice);            
        }
        
        if (this.bruttoSavingsContainer) {
            this.bruttoSavingsContainer.innerText = this.numberFormatForDisplay(newOldBruttoPrice - newBruttoPrice);
        }
    }

    update()
    {
        if (this.getNettoFlag()) {
            this.updateNetto();
        }
        else {
            this.updateBrutto();
        }
    }

    run()
    {
        document.addEventListener('DOMContentLoaded', () => {
            if (this.discountsConfigContainer) {
                this.getConfig();
            }
            if (this.minusButton) {
                this.minusButton.addEventListener('click', () => this.update());
            }
            if (this.amountField) {
                this.amountField.addEventListener('keyup', () => this.update());
            }
            if (this.plusButton) {
                this.plusButton.addEventListener('click', () => this.update());
            }
        });
        
        document.addEventListener('parametersPriceHasChanged', () => this.update());
    }
}
